<template>
  <div class="Site PageBox">
    <van-nav-bar
      fixed
      :border="false"
      :title="$t('hall.default[0]')" @click-right="onClickRight"
    ><van-icon name="./static/icon/task00.png" slot="right" />
    </van-nav-bar>
    <div class="tool">
      <table style="text-align: center;width: 95%;line-height: 25px;font-size: 18px;padding: 5px">
        <tr>
          <td width="15%">
            <div class="MyHeader" style="margin-right: 5px">
              <img class="head" :src="`./static/head/${UserInfo.header}`" />
            </div>
          </td>
          <td style="text-align: left">
            <div style="display: flex">
            <img :src="`./static/icon/vip${UserInfo.vip_level}.png`"  width="55px" height="18px"/>
            (<router-link class="href" slot="a" to="/busauth">{{$t('busAuth2[0]')}}</router-link>)
            </div>
            <div style="font-size:15px"> {{ $t("hall.default[1]") }} USDT:{{ parseFloat(UserInfo.balance).toFixed(2)}}</div>
          </td>

        </tr>
        <tr>
          <td colspan="2" style="text-align: left">
            <div style="margin-top: 10px;text-align: center">
              <van-button size="min" color="#F0B80D" @click="gotoBuy" style="width: 120px;height: 32px;margin-right: 10px;border-radius: 20px">
             <span style="font-size: 13px;color:#FFF">
            {{ $t("hall.default[2]") }}</span></van-button>
              <van-button size="min" color="#F0B80D" to="/sell" style="width: 120px;height: 32px;border-radius: 20px">
             <span style="font-size: 13px;color:#FFF">
            {{ $t("hall.default[3]") }}</span></van-button>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="title01">
      <van-grid :border="false" :column-num="2" icon-size="40" gutter="5">
        <van-grid-item>
          <van-button :color="active==0?'#F0B80D':'#252D30'"  @click="changeTab(1)">
            <span :class="active==0?'':'tt3'">{{ $t("buy3[4]") }}</span></van-button>
        </van-grid-item>
        <van-grid-item>
          <van-button :color="active==1?'#F0B80D':'#252D30'" class="tt3" @click="changeTab(0)">
            <span :class="active==1?'':'tt3'"> {{ $t("buy3[3]") }}</span></van-button>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="ScrollBox records">
      <van-pull-refresh v-model="isRefresh" @refresh="onRefresh" @load="onLoad">
        <van-list
                v-model="isLoad"
                :finished="isFinished"
                :finished-text="listData[active].length ? $t('vanPull[0]') : $t('vanPull[1]')"
                @load="onLoad"
                :class="{ Empty: !listData[active].length }"
        >
          <div v-for="item in listData[active]" style="color: #9b9b9b">
            <table class="sellitem">
              <tr>
                <td style="width: 80%;display: flex">
                  <div v-if="active==0" style="color: #e7e7e7;width: 220px">{{$t('busAuth3[3]')}}</div>
                  <div v-else style="color: #e7e7e7;width: 220px">{{$t('busAuth3[1]')}}</div>
                  <div v-if="item.counttime>0">{{$t('buy3[0]')}}: <span style="color: #aaaaaa">{{item.countdesc}}</span></div>
                </td>
                <td> <div class="price_rate">{{item.price_rate}}%</div></td>
              </tr>
              <tr>
                <td colspan="2" style="width: 50%"> {{item.username}}</td>
              </tr>
              <tr>
                <td colspan="2">{{ $t("hall.list[1]") }}: {{parseFloat(item.price).toFixed(2)}} </td>
              </tr>
              <tr>
                <td colspan="2">{{ $t("hall.list[0]") }}: {{parseFloat(item.total_number).toFixed(2)}} USDT </td>
              </tr>
              <tr>
                <td colspan="2">
                {{ $t("hall.list[2]") }}: {{parseFloat(item.remains_number).toFixed(2)}} USDT</td>
              </tr>
              <tr>
                <td colspan="2">
                  {{ $t("hall.list[6]") }}: {{parseFloat(item.min_number).toFixed(2)}}-{{parseFloat(item.max_number).toFixed(2)}} USDT
                </td>
              </tr>
              <tr>
                <td>
                  <div v-if="active==1">
                    <div v-if="item.count_limit>0">{{$t('buy3[5]')}} * {{item.count_limit}}</div>
                    <div style="display: flex">
                      <div v-for="(user,index) in item.users" :key="index" v-if="index<7" @click="goHmlist(item.id)">
                        <img class="robotheader" :src="'./static/head/'+user.header" />
                      </div>
                      <img v-if="item.users.length>0" class="robotheader" :src="`./static/icon/plus.png`" @click="goHmlist(item.id)"/>
                    </div>
                  </div>
                </td>
                <td style="text-align: right">  <van-button size="normal" color="#F0B80D" style="width: 100px;height: 28px"
                                                            @click="gotoTask(2,item)">
                  <span style="font-size: 14px;color: #000" >{{ $t("hall.default[10]") }}</span></van-button></td>
              </tr>
            </table>
            <div style="width:100%;height:1px; background:#E0E0E0;"></div>
          </div>
        </van-list>
      </van-pull-refresh>

      <div style="height: 80px;"/>
    </div>
    <Footer />
    <van-dialog v-model="show_buy"  :title="$t('hall.list[7]')" @confirm="addNewOrder"
                :show-confirm-button="true"  :show-cancel-button="true" @cancel="cancelTask">
      <div style="width: 90%;margin-top: 10px;margin-bottom: 30px">
        <van-field
                v-model="postData.amount"
                :placeholder="$t('hall.list[4]')" style="margin-left: 20px"
        />
        <div style="margin-left: 30px;margin-top: 10px">{{$t('hall.list[8]')}}::{{parseFloat(postData.amount*currItem.price).toFixed(2)}} {{usdtinfo.sign}}</div>
      </div>
    </van-dialog>
    <van-dialog v-model="show_sell"  :title="$t('hall.list[3]')" @confirm="addNewOrder"
                :confirmButtonText="$t('common[2]')" :cancelButtonText="$t('common[3]')"
                :show-confirm-button="true"  :show-cancel-button="true" @cancel="cancelTask">
      <div style="width: 90%;margin-top: 10px;margin-bottom: 30px">
        <van-field
                v-model="postData.amount"
                :placeholder="$t('hall.list[4]')" style="margin-left: 20px"
        />
        <div style="margin-left: 30px;margin-top: 10px">{{$t('hall.list[5]')}}:{{parseFloat(postData.amount*currItem.price).toFixed(2)}} {{usdtinfo.sign}}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'Help',
  components: {
  },
  props: [],
  data() {
    return {
      showCon: false,
      infoData: '',
      isVertical:true,
      show_sell:false,
      show_buy:false,
      levelStr:'',
      postData:{task_id:0,amount:''},
      usdtamount:0,
      usdtinfo:null,
      currItem:{"price":0},
      robotList:[],
      selllist:[],
      listData: [[],[]],
      timer:null,
      isLoad: false,
      userInfo:{},
      isFinished: false,
      isRefresh: false,
      pageNo: 0,
      hallInfo:{islock:1,shimingauth:1},
      active:1,
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    // let info = this.UserInfo;
    // debugger
    // if (!info['shimingauth']||info['shimingauth']!=1){
    //   this.$router.push("/shimingauth");
    //   return;
    // }

    var that = this;

    this.timer = setInterval(() => {
      // console.log("get prices");
      that.findTradeStatus();
    }, 3000);

    // let param0 = {};
    // this.$Model.GetRobotTasks(param0,(data) => {
    //   // debugger
    //   if (data['code']==1){
    //     this.robotList = data['info']
    //   }
    // });

    this.usdtinfo = this.InitData.usdtinfo;

    this.levelStr = this.$t('hall4[1]');

    let param2 = {"task_type":2,status:1};
    this.$Model.GetHallTaskList(param2,(data) => {
      if (data['code']==1){
        this.selllist = data['info']
      }
    });

    this.$Model.GetUserInfo((data) => {
      if (data.code == 1) {
        this.uid = data.info.uid;
        this.userInfo = data.info;
        var ut = this.userInfo['user_type'];
        // this.levelStr = this.$t("busAuth3["+ut+"]")
        this.levelStr = "VIP"+this.userInfo.vip_level
      }
    });
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  beforeDestroy() {
    console.log("clear  hall timer");
    clearInterval(this.timer);
  },
  methods: {
    onLoad() {
      this.getListData("load");
    },
    findTradeStatus(){
      this.$Model.TradeStatus((data) => {
        console.log("findTradeStatus")
        if (data['code']==1){
          this.hallInfo = data['info'];
          let info = this.hallInfo;
          if (info['bindAccount']==1) {
            this.$Dialog.Toast(this.$t('common4[7]'));
            this.$router.push("/user/bindAccount");
          }else if (info['shimingauth']==1){
            this.$Dialog.Toast(this.$t('common4[8]'));
            this.$router.push("/shimingauth");
          }else if (info['islock']==2){
            this.$Dialog.Toast(this.$t('common5[5]'));
            this.$router.go(-1);
          }
        }
      });
    },
    changeTab(index){
      this.active = index;
      this.getListData("init");
    },
    onRefresh() {
     // this.getListData("init");
    },
    goHmlist(id) {
      this.$router.push(`/hmlist/${id}`);
    },
    getListData(type) {
      this.isLoad = true;
      this.isRefresh = false;
      if (type == "load") {
        this.pageNo += 1;
      } else {
        this.pageNo = 1;
        this.isFinished = false;
      }

      let param = {"ordertype":this.active,status:1,page_no: this.pageNo,page_size:200};
      this.$Model.GetHallTaskList(param,(data) => {
        if (data['code']==1){
          data.info.forEach(item=>{
            var ctime = item.counttime;
            var countdesc = ''
            if (ctime==0){
              countdesc = this.$t('hall3[6]');
            }else{
              var countdesc = '';
              for (var i=0;i<ctime.length;i++){
                countdesc += ' '+ctime[i]+" "+this.$t('hall3['+i+']');
              }
            }
            item.countdesc = countdesc;
            var price_rate = (item.price-this.usdtinfo.price)/this.usdtinfo.price*100
            // price_rate = 13;
            price_rate = parseFloat(price_rate).toFixed(0);
            if (price_rate>0){
              price_rate = "+"+price_rate;
            }
            item.price_rate = price_rate
          });
          // this.selllist = data['info']
          if (type == "load") {
            if (this.pageNo == 1) {
              this.listData[this.active] = data.info;
            } else {
              this.listData[this.active] = this.listData[this.active].concat(data.info);
            }
          } else {
            this.listData[this.active] = data.info;
          }
          if (this.pageNo == data.data_total_page) {
            this.isFinished = true;
          } else {
            this.isFinished = false;
          }
        } else {
          this.listData[this.active] = [];
          this.isFinished = true;
        }
      });
    },
    onClickRight(){
      this.$router.push("/user/userTaskRecord");
    },
    gotoTask(index,item){
      if (this.hallInfo['islock']==2){
        this.$Dialog.Toast(this.$t('common5[5]'));
        return;
      }
      this.currItem = item;
      if (index==1){
        this.show_buy = true;
        this.show_sell = false;
      }else{
        this.show_sell = true;
        this.show_buy = false;
      }

    },
    cancelTask(){
      this.postData.amount = '';
    },
    gotoWithdraw(){
      let info = this.userInfo;
      if (info.withdraw_body&&info.withdraw_body.length>0){
        this.msg['title'] = info.withdraw_title;
        this.msg['content'] = info.withdraw_body;
        this.showMsg = true;
        return;
      }
      this.$router.push('/user/withdraw');
    },
    addNewOrder() {
      let amount = parseFloat(this.postData['amount']);
      let item = this.currItem;
      let hallstatus = this.hallInfo;
      if (amount<=0){
        this.$toast(this.$t('hall.kbip[2]'));
        this.postData.amount = '';
        return;
      }else if (amount>item.remains_number){
        this.$toast(this.$t('hall.kbip[1]'));
        this.postData.amount = '';
        return;
      }else if (item.min_number>0&&(amount<item.min_number||amount>item.max_number)){
        this.postData.amount = '';
        this.$toast(this.$t('hall.kbip[2]'));
        return;
      }else if (amount>item.remains_number){

      }

      this.postData['task_type'] = 2;
      this.postData['task_id'] = item.id;
      this.postData['order_number'] = item['order_number'];
      this.$Model.SubmitTaskOrder(
              this.postData,
              (data) => {
                let msg = '';
                switch (data.code){
                  case 1:
                    msg = this.$t('common3[0]');
                    break;
                  case -1:
                    msg = this.$t('hall.kbip[2]');
                    break;
                  case -2:
                    msg = this.$t('hall[2]');
                    break;
                  case -3:
                    msg = this.$t('taskOrder2[1]');
                    break;
                  case -4:
                    msg = this.$t('common4[7]');
                    break
                  case -5:
                    msg = this.$t('common4[8]');
                    break
                  default:
                    msg = data.code_dec;
                }
                let duration = 2000;
                if (data.wait){
                  duration = data.wait*1000
                }
                let msgg = {
                  message: msg,
                  duration: duration
                }
                this.$toast(msgg);
                if (data.code==1){
                  this.$router.push("/myTask");
                }
              }
      );
      this.postData.amount = '';
    console.log("xxxxxxxxx")
    },
    gotoBuy(){
      let usertype = this.UserInfo.user_type;
      if (usertype==0){
        this.$Dialog.Toast(this.$t("busAuth2[5]"));
        return;
      }else{
        this.$router.push("/buy");
      }
    }
  }
}
</script>
<style scoped>
  .PageBox {
    color: #635327;
    background-color: #13171A;
  }

  .van-nav-bar {
    background-color: #191C23;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#cccccc;
    font-weight: bold;
  }

.robotheader{
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 32px;
  height: 32px;
}

  .href {
    color: #AD890C;
  }

.records {
  background: #191C23;
  width: 95%;
  margin: auto;
  border-radius: 10px;
  color: #9b9b9b;
  margin-bottom: 10px;
  padding: 10px;
}
.Content>>>img{
  max-width: 100%;
}
.van-cell__title{
  font-size: 14px;
  color: #000;
}

.ScrollBox{
  height: 600px;
}

.itemDesc{
  border:2px solid #F0B80D;
  width: 36px;
  height: 36px;
  font-size: 12px;
  margin-left: 120px;
  text-align: center;
  background-color: #191C23;
  color: #fff;
  border-radius: 50%;
}

.PageBox >>> .van-grid-item__content {
  padding: 10px !important;
  display: flex;
  color: #cccccc;
  background-color:#191C23;
}

  .PageBox >>> .van-grid-item__text{
    color: #cccccc;
  }

  .PageBox>>> .van-dialog {
    background-color: #191C23;
    color: #aaaaaa;
  }

  .PageBox>>> .van-dialog__confirm{
    background-color: #191C23;
    color: #13C465;
  }

  .van-dialog>>>.van-cell {
    background: #353C4A;
    color: #fff;
  }

  .van-dialog>>>.van-field__control {
    background-color: #353C4A;
    color: #fff;
  }
  .PageBox>>> .van-dialog__cancel{
    background-color: #191C23;
    color: #cccccc;
  }

.title00 {
  background: #FDEAD9;
  width: 95%;
  color: #000;
  margin: auto;
  font-weight: bold;
  line-height: 40px;
  text-align: center
}
  .MyHeader {
    font-size: 19px;
    border-radius: 50%;
    background: pink;
    overflow: hidden;
    width: 52px;
    height: 52px;
  }
  .MyHeader img {
    width: 100%;
  }

.title01 {
  background: #191C23;
  width: 95%;
  color: #000;
  margin: auto;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  margin-bottom: 10px;
}

.sellitem {
  background-color: #191C23;
  width: 95%;
  text-align: left;
  font-size: 15px;
  margin-bottom: 10px;
  line-height: 22px;
  margin-top: 10px;
  border-radius: 10px;
}

.PageBox>>>.tool::before {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -80%;
}
.tool {
  height: 140px;
  border-radius: 1px;
  color: #fff;
  background-color: #191C23;
  /*box-shadow: 0 1px 1px #dbdbdbdb;*/
  overflow: hidden;
  z-index: 99;
  padding: 0 5px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.ScrollBox>>>.van-cell{
	padding:20px 5px;
	border-bottom: 1px solid #D9D9D9;
}
.ScrollBox>>>.van-cell:last-child{
	border-bottom: 0px solid #D9D9D9;
}
.ScrollBox>>>.van-cell::after{
	display: none;
}
.ScrollBox>>>.van-icon-chat-o::before{
	content: '';
	border-radius: 50%;
	background: #191C23;
	width:8px;
	height: 8px;
}
.ScrollBox>>>.van-icon-chat-o{
	display: flex;
	align-items: center;
	justify-content: center;
}
.ScrollBox>>>.van-cell__title{
	font-size: 16px;
	font-family: ui-serif;
}
.ScrollBox>>>h3{
	font-size: 1.05rem;
	font-family: emoji;
}
.van-cell>>>.van-icon{
	font-size: 18px;
	color:#333;
}

.hallswipe {
  width: 96%;
  margin-left: 6px;
  padding: 15px;
  height: 60px;
  z-index: 50;
  margin-top: 5px;
  margin-bottom: 5px;
  color:#cccccc;
  background-color: #191C23;
}


  .ScrollBox >>> .van-tab :nth-of-type(1) div {
    background-color: #000;
    padding: 5px;
    border: 1px solid;
    border-radius: 20px;
    color: #fff;
  }

  .ScrollBox >>> .van-tab--active :nth-of-type(1) div {
    background-color: #F5C73A;
    padding: 5px;
    border-radius: 20px;
    color: #000;
  }
  .ScrollBox >>> .van-tab--active :nth-of-type(1) div div {
    background-color: #F5C73A;
    padding: 5px;
    border-radius: 20px;
    color: #000;
  }

.PageBox>>>.van-popup__close-icon--top-right{
	color:#333;
	top:20px;
	right: 20px;
}
.PageBox>>>.Content{
	margin-top:40px;
	padding:0 20px;
}
.PageBox>>>.Content p{
	font-size: 13px;
	line-height: 1.7;
	font-family: 'vant-icon';
	font-weight: 400;
	color: #666666;
}

</style>
